
@media (min-width: 560px) {
    .tuby-controls {
        border-radius: 12px;
    }
    .tuby {
        border-radius: 12px;
    }
    video {
        border-radius: 12px;
    }
}

.tuby {
    --tuby-primary-color: #ff0000;
    font-family: Arial, Helvetica, sans-serif
}

.tuby-disable .tuby-controls {
    pointer-events: none;
}

.tuby button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0
}

.tuby * {
    user-select: none
}

.tuby {
    position: relative;
    color: #fff;
    background: #000
}

.tuby-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.tuby-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
    z-index: 1
}

.tuby video {
    width: 100%;
    height: 100%
}

.tuby-time {
    font-size: 14px
}

.tuby-progress-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tuby-progress {
    width: max(25%, 60px);
    transform-origin: center;
    animation: tuby-rotate 2s linear infinite;
}

@keyframes tuby-rotate {
    100% {
        transform: rotate(360deg)
    }
}

.tuby-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 1;
}

.tuby-show {
    opacity: 100% !important;
    visibility: visible !important
}

.tuby-icon-md {
    width: 40px;
    height: 40px
}

.tuby-icon-sm {
    width: 20px;
    height: 20px
}

.tuby-chevron {
    width: 15px;
    height: 15px
}

.tuby-center-container {
    display: flex;
    align-items: center;
    justify-content: center
}

.tuby [data-tuby-tooltips] {
    position: relative
}

.tuby [data-tuby-tooltips]::before {
    position: absolute;
    content: attr(data-tuby-tooltips);
    opacity: 0;
    transition: .15s;
    top: -50px;
    background: #000000b6;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    pointer-events: none;
    white-space: nowrap;
    width: max-content
}

.tuby [data-tuby-tooltips]:hover::before {
    opacity: 1
}

.tuby-tooltips-left::before {
    transform: none !important;
    left: -4px !important
}

.tuby-tooltips-right::before {
    transform: none !important;
    left: auto !important;
    right: -4px !important
}

.tuby-icon-underline {
    position: relative
}

.tuby-icon-underline::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    width: 20px;
    height: 3px;
    background: var(--tuby-primary-color);
    border-radius: 6px
}

.tuby-fade-zoom-out {
    opacity: 0;
    animation: tuby-fade-zoom-out .4s linear
}

.tuby-effect-icon {
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000088;
    border-radius: 99999px
}

@keyframes tuby-fade-zoom-out {
    from {
        opacity: 1;
        transform: scale(0.3)
    }

    to {
        opacity: 0;
        transform: scale(1.3)
    }
}

.tuby {
    --control-spacing: min(3vw, 20px);
    cursor: none
}

.tuby-controls-hovered:hover .tuby-controls {
    opacity: 1 !important;
    visibility: visible !important;
    cursor: default
}

.tuby-controls-hovered:hover {
    cursor: pointer
}

.tuby-time {
    white-space: nowrap
}

.tuby-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    background: linear-gradient(to bottom, transparent, #00000091);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    z-index: 2;
    cursor: none
}

.tuby-controls-main {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-grow: 1;
    padding: 0 8px 6px 8px
}

.tuby-controls-left {
    display: flex;
    gap: var(--control-spacing);
    align-items: center;
    padding: 0 var(--control-spacing)
}

.tuby-controls-right {
    display: flex;
    gap: var(--control-spacing);
    align-items: center;
    padding: 0 var(--control-spacing)
}

.tuby-volume-container {
    display: flex;
    align-items: stretch;
    gap: 6px
}

.tuby-volume-slider {
    height: 4px;
    background: #ffffff91;
    outline: none;
    border-radius: 1px;
    appearance: none;
    width: 52px;
    z-index: 1;
    cursor: pointer;
    margin: 0
}

.tuby-volume-slider::-webkit-slider-thumb {
    width: 12px;
    height: 12px;
    background: #fff;
    cursor: pointer;
    border-radius: 99999px;
    appearance: none
}

.tuby-volume-slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background: #fff;
    cursor: pointer;
    border-radius: 99999px;
    appearance: none
}

.tuby-volume-wrapper {
    width: 0;
    transition: .3s;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative
}

.tuby-volume-container:hover .tuby-volume-wrapper {
    width: 52px
}

@media(max-width: 640px) {
    .tuby-volume-container:hover .tuby-volume-wrapper {
        width: 0 !important
    }
}

.tuby-volume-left-bar {
    position: absolute;
    left: 0;
    height: 4px;
    background: #fff;
    width: 52px;
    border-radius: 1px
}

.tuby-seek {
    flex-shrink: 0;
    height: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin: 0 12px;
    position: relative
}

.tuby-seek:hover .tuby-seek-bar {
    height: 5px
}

.tuby-seek-bar {
    height: 3px;
    background: #ffffff80;
    transition: .15s
}

.tuby-seek-left {
    height: 100%;
    background: var(--tuby-primary-color);
    position: relative
}

.tuby-seek-left:after {
    content: "";
    position: absolute;
    height: 12px;
    width: 12px;
    right: -6px;
    top: 50%;
    transform: translateY(-50%) scale(0);
    border-radius: 99999px;
    background: var(--tuby-primary-color);
    transition: .15s
}

.tuby-seek:hover .tuby-seek-left::after {
    transform: translateY(-50%) scale(1)
}

.tuby-seek-preview {
    position: absolute;
    top: -24px;
    font-size: 12px;
    background: #000000b6;
    color: #fff;
    padding: 3px 7px;
    pointer-events: none;
    animation: tuby-fade-forward .2s forwards
}

@keyframes tuby-fade-forward {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.tuby-settings-dialog {
    position: absolute;
    bottom: 40px;
    right: -50px;
    width: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #1c1c1ce6;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
    border-radius: 10px;
    font-size: 14px;
}

.tuby-settings-outer {
    position: relative;
    transition: .3s
}

.tuby-settings-section {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px;
    max-height: 250px;
    transition: .3s;
    overflow-y: auto;
    overflow-x: hidden;
}

.tuby-settings-section-header {
    display: flex;
    align-items: center;
    padding: 0 15px;
    gap: 15px;
    cursor: pointer;
    min-height: 50px;
    border-bottom: 1px solid #565552;
}

.tuby-settings-py {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 0
}

.tuby-settings-translate-left {
    transform: translateX(-100%)
}

.tuby-settings-translate-center {
    transform: translateX(0)
}

.tuby-settings-translate-right {
    transform: translateX(100%)
}

.tuby-settings-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    cursor: pointer;
    transition: .2s;
    min-height: 35px
}

.tuby-settings-item p {
    margin: 0
}

.tuby-settings-item:hover {
    background: #343434
}

.tuby-settings-item-left {
    display: flex;
    align-items: center;
    gap: 10px
}

.tuby-settings-item-2 {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 15px;
    cursor: pointer;
    transition: .2s;
    min-height: 35px
}

.tuby-settings-item-2 p {
    margin: 0
}

.tuby-settings-item-2:hover {
    background: #343434
}

.tuby-settings-item-2-left {
    display: flex;
    align-items: center;
    gap: 10px
}

.tuby *::-webkit-scrollbar {
    width: 10px;
    height: 10px
}

.tuby *::-webkit-scrollbar-track {
    background: transparent
}

.tuby *::-webkit-scrollbar-thumb {
    border: 1px solid #191a1f;
    background: #666;
    border-radius: 16px
}

.tuby *::-webkit-scrollbar-thumb:hover {
    background: #777
}

.tuby *::-webkit-scrollbar-button {
    display: none
}

.tuby-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: .3s
}

.tuby-modal {
    width: 95vw;
    max-width: 200px;
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
    background: #f9f9f9;
    color: #000;
    padding: 20px
}

.tuby-modal h1 {
    margin: 0;
    font-weight: normal;
    font-size: 25px
}

.tuby-modal p {
    margin: 0;
    margin-top: 15px;
    margin-bottom: 2px;
    font-size: 15px
}

.tuby-modal select {
    cursor: pointer;
    padding: 10px;
    margin: 0;
    border: 1px solid #d8d8d8;
    outline: none;
    background: #f1f1f1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%
}

.tuby-modal option {
    background: #f1f1f1;
    width: 100%
}

.tuby-modal-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px
}